import React from "react"
import Layout from "../components/Layout/layout"

const Impressum = () => (
  <Layout slug = 'Impressum'>
  <section class="section" div="values">
    <div class="container is-fluid wrapper standard-margin">
    <div class="content">

    <h1 class="title has-text-primary has-text-weight-light">Impressum</h1>
    <h2 class="subtitle has-text-black has-text-weight-light">travel connection Touristik GmbH</h2>
    <br/><br/>
    Kaiserstraße 34
    D 40764 Langenfeld
    <br/><br/>
    Tel.: 02173 9841841
    Fax: 02173 9841831
    E-Mail: info@schoenesreisen.de
    <br/><br/>
    Geschäftsführerin: Julietta Gladbach
    <br/><br/>
    Registergericht: Düsseldorf
    Registernummer: HRB 46258
    <br/><br/>
    <br/><br/>
    Gemäß § 37 VSBG sind wir verpflichtet, Sie darauf hinzuweisen, dass für Streitigkeiten zwischen Verbrauchern und Unternehmen Streitschlichtungsstellen eingerichtet wurden. Wir nehmen derzeit nicht an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teil und sind dazu nicht verpflichtet. Sie können sich an diese Stellen wenden, die Teilnahme ist für beide Seiten aber freiwillig.
    <br/><br/>
    Allgemeine Verbraucherschlichtungsstelle: <br/>
    Zentrum für Schlichtung e.V. | Straßburger Str. 8 77694 Kehl | mail@verbraucher-schlichter.de
    <br/><br/>
    Wir vermitteln Reiseversicherungen im Status eines erlaubnisfreien Annexvermittlers gemäß § 34d Abs. 8 Nr. 1 Gewerbeordnung (GewO).
    Beschwerdestelle bei Streitigkeiten mit Versicherungsvermittlern: <br/>
    Versicherungsombudsmann e.V. | Postfach 08 06 32 | 10006 Berlin | www.versicherungsombudsmann.de
    <br/><br/>
    Online-Streitbeilegung gemäß Art. 14 Abs. 1 ODR-Verordnung: <br/>
    Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS-Plattform) bereit. Sie erreichen diese unter http://ec.europa.eu/consumers/odr/.
    <br/>
    </div>
    </div>
    </section>
  </Layout>
)

export default Impressum
